<template>
  <div class="RoomLib">
    <Modal ref="RoomLib">
      <template slot="header">
        <h5 class="modal-title">选择房间</h5>
        <!--<div class="input-group input-group-sm ">
            <div class="input-group-prepend">
                <span class="input-group-text" style="font-weight:700">标签</span>
            </div>
            <select class="form-control" v-model:value="query.tag" v-on:change="search()" placeholder="标签">
                <option value="">所有</option>
                <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
            </select>
        </div>-->
      </template>
      <div class="card-tools py-1">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" :readonly="loading" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" :disabled="loading" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="row">
          <div v-if="loading" style="flex:auto;display:flex;text-align:center;justify-content:center">
            <div>
              <i class="fas fa-spinner fa-pulse"></i>
              <p>
                载入中…
              </p>
            </div>
          </div>
          <div v-else-if="list.length==0" style="flex:auto;display:flex;text-align:center;justify-content:center">
            <div class="col-12 col-sm-6">
              <div class="small-box bg-gray" style="height: 150px;" @click="select({id:null,title:'公共区域'})">
                <div class="inner">
                  <h4 class="text-truncate">公共区域</h4>
                </div>
                <div class="small-box-footer">
                </div>
              </div>
            </div>
            <div>
              没有找到匹配项目
            </div>
          </div>
          <template v-else>
            <div class="col-12 col-sm-6">
              <div class="small-box bg-gray" style="height: 150px;" @click="select({id:null,title:'公共区域'})">
                <div class="inner">
                  <h4 class="text-truncate">公共区域</h4>
                </div>
                <div class="small-box-footer">
                </div>
              </div>
            </div>
            <template v-for="i in list">
              <div class="col-12 col-sm-6" v-if="!isExclude(i.id)">
                <div class="small-box bg-gray" style="height: 150px;" v-bind:style="resizeimg(i.imageUrl||'')" @click="select(i)">
                  <div class="inner">
                    <h4 class="text-truncate">{{i.title}}</h4>
                  </div>
                  <div class="small-box-footer">
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <template slot="footer">
        <paging :page-index="pager.pageIndex"
                :total="pager.totalRecords"
                :page-size="pager.pageSize"
                :per-pages="3"
                :show-go="true"
                :prePageSize="pager.prePageSize"
                :onPagerChange="pageChange">
        </paging>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { Modal } from 'common'
  export default {
    name: 'RoomLib',
    props: {
      libUrl: String,
      params: Object,
      onSelected: Function,
      exclude: Array,
    },
    data: function () {
      return {
        list: [],
        pager: {
          prePageSize: [12, 24, 48, 96, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 100,
          sort: "Id",
          order: "desc",
          fields: 'Title',
          key: null,
          tag: null
        },
        loading: false,
      }
    },
    created() {
    },
    methods: {
      isExclude(guid) {
        if (this.exclude) {
          if (this.exclude.indexOf(guid) != -1) {
            return true
          }
        }
        return false
      },
      getRooms(request) {
        this.loading = true
        console.log(request)
        this.$axios.get(this.libUrl, request)
          .then((res) => {
            this.list = res.data.data;
            this.pager.pageIndex = res.data.pageIndex
            this.pager.pageSize = res.data.pageSize
            this.pager.totalPages = res.data.totalPages
            this.pager.totalRecords = res.data.totalRecords
            this.loading = false
          })
          .catch((res) => {
            console.log(res)
            this.loading = false
          })
      },
      loadData() {
        this.getRooms({
          params: {
            page: this.query.pageIndex,
            pageSize: this.query.pageSize,
            sort: this.query.sort,
            order: this.query.order,
            fields: this.query.fields,
            key: this.query.key,
            roles: this.query.roles,
            ...(this.params || {}),
          }
        })
      },
      search() {
        this.query.pageIndex = 1;
        this.loadData()
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      sortingChanged(ctx) {
        // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
        // ctx.sortDesc ==> true if sorting descending, false otherwise
        this.query.sort = ctx.sortBy;
        this.query.order = ctx.sortDesc ? "desc" : "asc";
        this.loadData();
      },
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',
        }
      },
      showDialog() {
        this.$refs.RoomLib.show()
        //$('#MatModal').modal('show')
        this.loadData()
      },
      select(item) {
        console.log(this.onSelected)
        if (this.onSelected) {
          this.onSelected(item)
        }
        this.$refs.RoomLib.hide()

        // $('#MatModal').modal('hide')
      },
      cancelSelect() {
        this.$refs.RoomLib.hide()

        //$('#MatModal').modal('hide')
      }
    },
    computed: {

    },
    components: {
      Modal
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
  .RoomLib .small-box .inner h4 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1.2rem;
    text-align: left;
  }

  .RoomLib .small-box .inner h5 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1.1rem;
    text-align: left;
  }

  .RoomLib .small-box .inner h6 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1rem;
    text-align: left;
  }

  .RoomLib .small-box .small-box-footer {
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
</style>
